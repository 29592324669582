import { Dayjs } from "dayjs";

const minBookDays = 3;

export function generateDateRanges(
  dates: { start: Dayjs; end: Dayjs }[]
): Dayjs[] {
  const resDates: Dayjs[] = [];
  dates.forEach(function(datePair) {
    resDates.push(...generateDateRange(datePair.start, datePair.end));
  });

  return resDates;
}

export function generateDateRange(
  dateFrom: Dayjs | null,
  dateTo: Dayjs | null
): Dayjs[] {
  if (!dateFrom || !dateTo) return [];
  const dates: Dayjs[] = [];
  let currentDate = dateFrom;
  while (currentDate <= dateTo) {
    dates.push(currentDate);
    currentDate = currentDate.add(1, "day");
  }

  return dates;
}

const isDateDisabled = (bookedDates: Dayjs[], date: Dayjs | null): boolean => {
  return date
    ? bookedDates.some((d) => d.isSame(date, "day")) ||
        date.isBefore(Date.now() + 1)
    : false;
};

export const isDateDisabledForDateFrom = (
  date: Dayjs,
  dateTo: Dayjs | null,
  bookedDates: Dayjs[]
) => {
  return (
    (dateTo ? date.add(minBookDays, "day").isAfter(dateTo, "day") : false) ||
    isDateDisabled(bookedDates, date)
  );
};

export const isDateDisabledForDateTo = (
  date: Dayjs,
  dateFrom: Dayjs | null,
  bookedDates: Dayjs[]
) => {
  return (
    (dateFrom
      ? date.add(-minBookDays, "day").isBefore(dateFrom, "day")
      : false) || isDateDisabled(bookedDates, date)
  );
};
