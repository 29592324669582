import QRCode from 'qrcode';
import { IReservationForm } from './IReservationForm';
import { bic, iban } from './PaymentInfo';

export const generateSepaQRCode = async (f: IReservationForm): Promise<string> => {
    const buyerName = `${f.firstName} ${f.lastName}, ${f.title}`;
    const orderId = `${buyerName} ${new Date().toISOString()}`;
  const sepaQrContent = [
    'BCD',
    '002',
    '1',
    'SCT',
    bic,
    'lovebania.com',
    iban,
    `EUR${f.amount.toFixed(2)}`
  ].join('\n');

  try {
    const qrCodeDataUri = await QRCode.toDataURL(sepaQrContent, {
      errorCorrectionLevel: 'M',
      width: 300
    });
    return qrCodeDataUri;
  } catch (err: any) {
    throw new Error(`QR failed: ${err.message}`);
  }
};
