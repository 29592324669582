// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Arial, sans-serif;
  text-align: start;
  margin: auto !important;
}
body h1 {
  text-align: center;
}
body fieldset {
  border: 0 !important;
}
body a {
  color: darkorange;
  font-weight: bolder;
}
body .home-carousel .swiper-slide {
  max-height: 650px;
}
body .home-carousel .swiper-slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,iBAAA;EACA,uBAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,oBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,mBAAA;AADJ;AAKI;EACE,iBAAA;AAHN;AAKM;EACE,iBAAA;EACA,WAAA;EACA,YAAA;AAHR","sourcesContent":["body {\n  font-family: Arial, sans-serif;\n  text-align: start;\n  margin: auto !important;\n\n  h1 {\n    text-align: center;\n  }\n\n  fieldset {\n    border: 0 !important;\n  }\n\n  a {\n    color: darkorange;\n    font-weight: bolder;\n  }\n\n  .home-carousel {\n    .swiper-slide {\n      max-height: 650px;\n\n      img {\n        object-fit: cover;\n        width: 100%;\n        height: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
