import { IReservationForm } from "./IReservationForm";

const API_URL = "https://btcpay.lovebania.com";
const STORE_ID = "DXUkPbtkVHgS5ThboJWrtcNqTkMEtvdBXui7xG1WGfCL";
const API_KEY = "d0a8222b504a415783d5325d9653933fef102d3c";

export const createInvoice = async (f: IReservationForm) => {
  try {
    const buyerName = `${f.firstName} ${f.lastName}, ${f.title}`;
    const orderId = `${buyerName} ${new Date().toISOString()}`;
    const invoiceData = {
      "amount": f.amount,
      "currency": "EUR",
      "metadata": {
        "orderId": orderId,
        "buyerName": buyerName,
        "buyerEmail": f.email,
        "buyerPhone": f.phone,
        "buyerCountry": f.country,
        "buyerAddress1": f.address,
        "buyerCity": f.city,
        "itemDesc": `Accomodation from ${f.dateFrom?.format('L')} to ${f.dateTo?.format('L')} for ${f.numberOfGuests} person(s)`,
        "receiptData": {
          "note": f.note
        }
      },
      "checkout": {
        "redirectURL": `${window.location.href}?payment=ok`
      }
    };

    const response = await fetch(`${API_URL}/api/v1/stores/${STORE_ID}/invoices`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${API_KEY}`,
      },
      body: JSON.stringify(invoiceData),
    });

    console.log(response);
    if (!response.ok) {
      throw new Error(`Failed to create invoice: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error creating invoice:", error);
    throw error;
  }
};
