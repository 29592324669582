import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

type MarkerData = {
  id: string;
  position: google.maps.LatLngLiteral;
  title: string;
};

export const lovebaniaPosition: google.maps.LatLngLiteral = {
  lat: 41.27582,
  lng: 19.51911
};

const markers: MarkerData[] = [
  { id: '1', position: lovebaniaPosition, title: 'Lovebania.com' }
];

const GoogleMapComponent: React.FC = () => {
  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!mapRef.current) return;

    const loader = new Loader({
      apiKey: 'AIzaSyABK1ML32NMjysz-y_nl4pUfE4_FepafdM',
      version: 'weekly',
    });

    loader.importLibrary('maps').then((mapsLibrary) => {
      const map = new mapsLibrary.Map(mapRef.current as HTMLElement, {
        center: lovebaniaPosition,
        zoom: 15,
        mapId: '8ff931a9f2e2a93e'
      });

      loader.importLibrary('marker').then((markerLibrary) => {
        markers.forEach((marker) => {
          const advancedMarker = new markerLibrary.AdvancedMarkerElement({
            map,
            position: marker.position,
            title: marker.title,
          });

          advancedMarker.addListener('click', () => {
            const mapsUrl = `https://www.google.com/maps?q=${marker.position.lat},${marker.position.lng}`;
            window.open(mapsUrl, '_blank');
          });
        });
      });
    });
  }, []);

  return (
    <div>
      <div
        ref={mapRef}
        style={{ width: '100%', height: '600px', marginTop: '20px' }}
      />
    </div>
  );
};

export default GoogleMapComponent;
