import React from 'react';
import MetaTags from './components/MetaTags';
import ReservationForm from './components/Reservation/ReservationForm';

function Reservation(props: LanguageDetail) {

  return (
    <>
      <MetaTags 
        description={props.abstract}
        title={props.reservation}
        url={window.location.href} />
      <h1>{ props.reservation }</h1>
      <ReservationForm {...props} />
    </>
  );
}

export default Reservation;
