import { AppBar, Button, Drawer, IconButton, List, ListItem, ListItemText, Toolbar, useMediaQuery } from "@mui/material";
import { Menu as MenuIcon } from '@mui/icons-material';
import React, { useState } from "react";
import LanguageSelector, { Translations } from "./LanguageSelector";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { isDev } from "../utils/DevUtils";

interface IMenu {
  translations: Translations;
  selectedLanguage: string;
  languageDetail: LanguageDetail;
}

function Menu(props: IMenu) {
  const t = props.languageDetail;
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { text: t.home, path: t.code },
    { text: t.reservation, path: `${t.code}/reservation` },
    { text: t.contact, path: `${t.code}/contact` },
  ];

  const menuItemsFiltered = menuItems.filter(i => i.text !== t.reservation || isDev());

  return (
    <AppBar position="static">
      <Toolbar>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
              <List>
                {menuItemsFiltered.map((item) => (
                  <ListItem key={item.text} onClick={() => setDrawerOpen(false)}>
                    <ListItemText
                      primary={item.text}
                      onClick={() => navigate(item.path)}
                    />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </>
        ) : (
          <>
            {menuItemsFiltered.map((item) => (
              <Button color="inherit" key={item.text} component={Link} to={item.path}>
                {item.text}
              </Button>
            ))}
          </>
        )}
        <LanguageSelector
          {...props.translations}
          selectedDetail={t}
          onChange={(lang) => {
            navigate(lang);
          }}
        />
      </Toolbar>
    </AppBar>
  );
}

export default Menu;
