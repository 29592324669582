import { generateDateRange, isDateDisabledForDateFrom, isDateDisabledForDateTo } from "../../utils/DateUtils";
import { IReservationForm } from "./IReservationForm";

export function getEmptyFormState(tryFromSession: boolean): IReservationForm {
    if (tryFromSession && sessionStorage.getItem("formState")) {
    }
    return {
      title: '',
      email: '',
      phone: '',
      numberOfGuests: 1,
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      country: '',
      note: '',
      amount: 0,
      dateFrom: null,
      dateTo: null,
    };
  }

export function checkForm (formState: IReservationForm) {
      if (!formState.dateFrom || !formState.dateTo) return false;
      const checkedDates = generateDateRange(
        formState.dateFrom,
        formState.dateTo
      );
      const isDisabledFrom = isDateDisabledForDateFrom(
        formState.dateFrom,
        formState.dateTo,
        checkedDates
      );
      const isDisabledTo = isDateDisabledForDateTo(
        formState.dateTo,
        formState.dateFrom,
        checkedDates
      );
      return isDisabledFrom && isDisabledTo;
    }