import React from "react";
import { Grid2 as Grid, Box, Container } from "@mui/material";
import albania from './img/albania.webp';
import home1 from './img/home1.webp';
import home2 from './img/home2.webp';
import home3 from './img/home3.webp';
import MetaTags from "./components/MetaTags";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';

function Home(props: LanguageDetail) {
  return (
    <>
      <MetaTags 
        description={props.abstract}
        title={props.headline}
        url={window.location.href} />
      <h1>{props.headline}</h1>
      <Container>
        <Swiper 
          className="home-carousel"
          loop={true}
          pagination={true} 
          effect={'fade'}
          modules={[Autoplay, EffectFade, Pagination]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
        >
          <SwiperSlide><img src={albania} /></SwiperSlide>
          <SwiperSlide><img src={home1} /></SwiperSlide>
          <SwiperSlide><img src={home2} /></SwiperSlide>
          <SwiperSlide><img src={home3} /></SwiperSlide>
        </Swiper>
      </Container>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            {props.homeAlbania}
          </Grid>
          <Grid size={{ xs: 12 }}>
            {props.homeGolem}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Home;
