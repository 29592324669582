import React from 'react';
import { Helmet } from 'react-helmet';
import { supportedLanguages } from './LanguageSelector';
import { isDev } from '../utils/DevUtils';

export interface MetaTagsProps {
  title: string;
  description: string;
  url: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({ title, description, url }) => {
  const { protocol, hostname, pathname, origin } = window.location;
  const canonicalUrl = `${origin}${pathname}`
  const baseUrl = `${protocol}//${hostname}/`;
  const currentLanguage = supportedLanguages.find((lang) =>
    pathname.startsWith(`/${lang}`)
  ) ?? 'en';


  return (<Helmet>
    <title>{title}</title>
    <link rel="canonical" href={canonicalUrl} />
    { isDev() && <meta name="robots" content="noindex, nofollow" />}
    <meta name="description" content={description} />

    {supportedLanguages
      .filter(l => l !== currentLanguage)
      .map((lang) => {
        const basePath = currentLanguage
          ? pathname.replace(`/${currentLanguage}`, '')
          : pathname;
        const href = `${baseUrl}${lang}${basePath}`;
        return (
          <link
            key={lang}
            rel="alternate"
            hrefLang={lang}
            href={href}
          />
        );
      })}

    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={url} />

    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
  </Helmet>);
};

export default MetaTags;
